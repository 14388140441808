import { TwoSwitch }                                    from "../lib/iprs-react-library/src/index.js";
import ReferrerForm                                     from "./ReferrerForm";
import Thanks                                           from "./Thanks";
import { useState }                                     from "react";
import { PROG_STATE_SHOW_FORM, PROG_STATE_SUBMITTED }   from "./ProgressBar";

const ReferralFormOrThanks = ({api, fieldDefinitions, clientId, onCancel, setProgressState}) => {

    // if we've submitted then we get a case ref and sometimes a booking URL
    const [caseRef, setCaseRef]         = useState(null);
    const [bookingURL, setBookingURL]   = useState(null);

    const onFormSubmitted = response => {
        if(response.BookingURL){
            setBookingURL(response.BookingURL);
        }
        setCaseRef(response.CaseRef);
        setProgressState(PROG_STATE_SUBMITTED);
    }

    // Resets state of 'caseRef, 'fieldFormData' and 'message'. 
    // When these states are resetted to their default values,
    // the 'thanks' will be replaced with the empty form and 
    // the button's text will be resetted to 'Submit'
    const anotherReferral = e => {
        e.preventDefault();
        setCaseRef(null);
        setBookingURL(null);
        
        setProgressState(PROG_STATE_SHOW_FORM);
    }

    return <TwoSwitch test={null === caseRef} >
        <ReferrerForm api={api} fieldDefinitions={fieldDefinitions} 
            clientId={clientId} onFormSubmitted={onFormSubmitted} onCancel={onCancel} />
        <Thanks caseRef={caseRef} bookingURL={bookingURL} anotherReferral={anotherReferral} />
    </TwoSwitch>
}

export default ReferralFormOrThanks;