import fixAutocomplete                  from './FixAutocomplete';
import replicateAllFieldsToLowerCase    from './replicateAllFieldsToLowerCase';

const makeFieldDefinitionsCanonical = fieldDefinitions => {

    let result = fieldDefinitions.map((f, i) => {

        let newFieldDefinition = replicateAllFieldsToLowerCase(f);

        // Allow only valid autocomplete and off by default
        newFieldDefinition.autocomplete = fixAutocomplete(newFieldDefinition.autocomplete);

        const options = getOptions(newFieldDefinition);
        if(Array.isArray(options)){
            delete (newFieldDefinition.item);
            newFieldDefinition.options = 
                options.map(makeKeysLower).map(makeOptionCanonical);
        }

        // note the key is very important to ensure repopulating on form changes
        newFieldDefinition.name = newFieldDefinition.name?? newFieldDefinition.controltype;
        newFieldDefinition.key  = newFieldDefinition.name?? 'item_number_i';

        return newFieldDefinition;
    });

    return result;
};

const getOptions = newFieldDefinition => {
    let options;
    if (Array.isArray(newFieldDefinition.item) && !Object.hasOwn(newFieldDefinition, 'options')) {
        options = newFieldDefinition.item;
    }else if(Array.isArray(newFieldDefinition.options)){
        options = newFieldDefinition.options;
    }else if(Array.isArray(newFieldDefinition.Options)){
        options = newFieldDefinition.Options;
    }else{
        options = null;
    }
    return options;
}

const makeKeysLower = object => {
    if( typeof object === 'string'){
        return object;
    }else{
        const result = {};
        Object.keys(object).forEach(key => {
            const lowerKey = key.toLocaleLowerCase();
            if(!Object.hasOwn(result, lowerKey)){
                result[lowerKey] = object[key];
            }
        });
        return result;
    }
}

const makeOptionCanonical = option => {

    let Value, Text;
    if( typeof option === 'string'){
        Value = Text = option;
    } else if (typeof option === 'object'){
        
        const {text, name, value} = option;
        
        Value = name?? (value?? (text?? ''));
        Text  = text?? (value?? (name?? ''));
    }
    
    Text    = Text?? '';
    Value   = Value?? '';

    const result = { Value, Text };
    return result;
}

export default makeFieldDefinitionsCanonical;