import { TwoSwitch }                            from "../lib/iprs-react-library/src/index";
import { useState }                             from "react";
import ReferralClientList                       from "./ReferralClientList";
import ProgressBar, { PROG_STATE_LOGGED_IN, PROG_STATE_SHOW_FORM }    from './ProgressBar';
import FormOrFormSelect                         from "./FormOrFormSelect";

const AppMainPart = ({ api }) => {

    const [client, setClient] = useState(null);

    const [progressState, setProgressState] = useState(PROG_STATE_LOGGED_IN);

    const selectReferrer = client => {
        setClient(client);
        setProgressState(PROG_STATE_SHOW_FORM);
    }

    return <>
        <ProgressBar progressState={progressState} />
        <div class="main__section">
            <div className="container typography">
                <TwoSwitch test={null !== client} >
                    <FormOrFormSelect api={api} clientID={client?.PartyID}
                        clientTitle={client?.Name} onCancel={() => {setClient(null);setProgressState(PROG_STATE_LOGGED_IN)}} 
                        setProgressState={setProgressState} />
                    <ReferralClientList api={api} selectReferrer={selectReferrer} />
                </TwoSwitch>
            </div>
        </div>
    </>
}

export default AppMainPart;