const ContactInformation = props => <div className="contactInfo">
    <hr />
    <h2>Contact Information</h2>
    <p>For any queries please do not hesitate to contact the Client Relations Team via telephone, email or via live chat.</p>
    <p>
        <i class="fa fa-phone" style={{ paddingRight: '10px' }}></i>01473 835486<br />
        <i class="fa fa-envelope" style={{ paddingRight: '10px' }}></i>
        <a href="mailto:client.relations@iprsgroup.com">client.relations@iprsgroup.com</a>
    </p>
</div>

export default ContactInformation;