import React                                                from 'react';

/* 
 * React component to make a heading section with heading and sub heading
 */
const Heading = (props) => {
    
    const {text, subheading = null, level: levelSt = ''} = props;
    const level = Number.parseInt( levelSt );
    
    return <div>
    
        {React.createElement(
            2<level? 'h3': 'h2',
            {className: (1<level? '': 'center')},
            [<>{text}</>]
        )}

        {subheading && React.createElement(
            'p',
            {className: (1<level? '': 'center')},
            [<>{subheading}</>]
        )}
    </div>
}

export default Heading;


