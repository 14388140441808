import { TwoSwitch } from "../lib/iprs-react-library/src/index";
import Check from           './images/check.svg';

/**
 * Thanks you page that will be displayed when form has been successfully submitted.
 * @param {object} props 
 * @returns 
 */
const Thanks = ({caseRef, bookingURL, anotherReferral}) => {

    // When 'Book a diary slot' button is clicked in the Thank you page, 
    // window.location.replace(bookingURL) will direct the user to booking page.
    const bookDiarySlot = () => {
        window.location.replace(bookingURL);
    }

    return <div class="container">
        <div class="typography">
            <div class="checkContainer">
                <img alt="Check Sign Icon" class="check" src={Check} />
            </div>

            <p>Thank you for submitting
                your referral to IPRS Health. You will receive a
                confirmation email within the next 30 minutes confirming
                the IPRS Health Case ID for future reference. If you have
                any concerns or do not receive this email please do not
                hesitate to contact IPRS Health on 0800 072 1227 or email -
                <a href="mailto:health@iprsgroup.com">health@iprsgroup.com</a>.
            </p>

            <p style={{ textAlign: 'center', marginTop: '30px' }}>Click <a id="anotherSubmitLink" href='.' onClick={anotherReferral}>here</a> to submit another referral.</p>

            <TwoSwitch test={bookingURL} >
                <button style={{ display: 'flex', marginBottom: '40px' }} onClick={() => bookDiarySlot()}>Book a diary slot</button>
            </TwoSwitch>
        </div>
    </div>
}

export default Thanks;

