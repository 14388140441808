import React from "react";

/**
 * Component to handle the Documents control type in form definitions.
 * Have added this in webreferral for now as not ready to share it in the library
 * We get a filed in the JSON looking like:-
 * ```
 *   {
    "name": "Documents",
    "text": "Doc Text",
    "enabled": "true",
    "controlType": "Documents",
    "Item": [
        {
            "Text": "Link 1",
            "Value": "https://link1.example"
        }
    ]
}
 * ```
 * which needs rendering as a list of links  
 * @param {object} props 
 * @returns 
 */
const Documents = props => {

    const headingText = props.text?.length? props.text: 'Useful documents';

    return <div className='list-wrapper useful-documents-section'>
        <h2 className='useful-documents-heading' >{headingText}</h2>
        <ul>{props.options.map(i => React.createElement(Link, i, null))}</ul>
    </div>
}

const Link = (i) => <li key={i.Value} ><a target='_blank' rel='noreferrer' href={i.Value?? 'http://nolink.invalid'}>{i.Text?? 'Useful document'}</a></li>

export default Documents;