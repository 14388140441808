import { TwoSwitch } from "../../lib/iprs-react-library/src/index.js";
import { useState } from "react";

const emptyValues = { FirstName: "", LastName: "", EmailAddress: "" };

const AddReferrerForm = ({ api, clientList, urlToken, setLoading }) => {
    const [error, setError] = useState(null);
    const [selectedClients, setSelectedClients] = useState([]);

    const [values, setValues] = useState({ ...emptyValues });

    const handleClick = e => {
        e.preventDefault();

        setLoading(true);
        setError(null);

        api.transact("UploadReferrer", {
            token: urlToken,
            ...values,
            SendResetPasswordEmail: true,
            ClientList: convertListOfClientIdsToClientObjects(selectedClients)
        })
            .then(r => r.apiResult)
            .then(r => {
                setError("Submited sucessfully.");
                setLoading(false);
                setValues({ ...emptyValues });
                setSelectedClients([]);
            })
            .catch(e => {
                setError(e.message);
                setLoading(false);
            });
    };

    const handleChangeSelectedClients = e => {
        const options = [...e.target.selectedOptions];
        const values = options.map(option => option.value);
        setSelectedClients(values);
    };

    const handleFormValueChange = e => {
        const newValues = { ...values };
        newValues[e.target.name] = e.target.value;
        setValues(newValues);
    };

    const dropDownSize = Math.max(1, Math.min(clientList.length, 5));

    return (
        <div>
            <TwoSwitch test={error}>
                <p>
                    <strong>Response</strong>: <span>{error}</span>
                </p>
            </TwoSwitch>
            <form onSubmit={handleClick}>
                <fieldset>
                    <legend
                        style={{
                            marginBottom: "3em",
                            marginLeft: "1em",
                            lineHeight: "1.5"
                        }}
                    >
                        When you submit this form, you will create an account
                        for the named person, identified by their email address.
                        You can select one or more clients for whome the
                        recipient will be able to make referrals. You can select
                        more than one client, by dragging with your mouse or by
                        holding down the 'ctrl' key as you select.
                        <br />
                        An Email will be sent to the recipient email address
                        giving them details of their new account.
                    </legend>
                    <div className="form__field form__field--full-width">
                        <label
                            htmlFor="selected-clients"
                            className="form__label"
                        >
                            Select one or more clients *
                        </label>
                        <div className="">
                            <select
                                multiple={true}
                                id="selected-clients"
                                name="select-clients"
                                value={selectedClients}
                                onChange={handleChangeSelectedClients}
                                required={true}
                                className="form__dropdown_____"
                                size={dropDownSize}
                                autoComplete="off"
                            >
                                {clientList.map(c => (
                                    <option
                                        value={c.ClientID}
                                        key={c.ClientID}
                                        className="form__mock-dropdown-option__"
                                    >
                                        {c.ClientName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="form__field form__field--full-width">
                        <label htmlFor="FirstName" className="form__label">
                            First Name *
                        </label>
                        <input
                            id="FirstName"
                            name="FirstName"
                            value={values["FirstName"]}
                            placeholder="First Name"
                            required={true}
                            onChange={handleFormValueChange}
                            autocomplete="off"
                        />
                    </div>

                    <div className="form__field form__field--full-width">
                        <label htmlFor="LastName" className="form__label">
                            Last Name *
                        </label>
                        <input
                            id="LastName"
                            name="LastName"
                            value={values["LastName"]}
                            placeholder="Last Name"
                            required={true}
                            onChange={handleFormValueChange}
                            autoComplete="off"
                        />
                    </div>

                    <div className="form__field form__field--full-width">
                        <label htmlFor="EmailAddress" className="form__label">
                            Email Address *
                        </label>
                        <input
                            id="EmailAddress"
                            name="EmailAddress"
                            type="email"
                            value={values["EmailAddress"]}
                            placeholder="Email Address"
                            required={true}
                            onChange={handleFormValueChange}
                            autoComplete="off"
                        />
                    </div>
                </fieldset>
                <button>Submit</button>
            </form>
        </div>
    );
};

/**
 * Converts to specific format required by UploadReferrer
 * @param {*} clientList
 * @returns
 */
const convertListOfClientIdsToClientObjects = clientList =>
    clientList.map(cid => ({
        Selected: true,
        ClientID: Number.parseInt(cid, 10)
    }));

export default AddReferrerForm;
