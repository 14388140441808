import React                            from    'react';

/* 
 * Section break component. Currently just an hr element
 */
const SectionBreak = props => {
    
    return <hr />;
};

export default SectionBreak


