import React, {useEffect, useState}                     from 'react'

import decodeJWT                                        from '../shared/JWTParser';

// the base hosting URL of the site. CSS and images are relative
const PUBLIC_URL=process.env.PUBLIC_URL;


const AccountMenu = ({handleLoginEvent, api}) => {
    
    const [firstName, setFirstName]             = useState( '' )
    const [lastName, setLastName]               = useState( '' )
    const [error, setError]                     = useState( '' )
    
    useEffect( () => {
      
        const jwt = decodeJWT( api.getToken() );
        
        if( jwt && jwt.UserID ){
            api.transact( 'PersonLoad', 
            {partyid: jwt.UserID }
            )
            .then( r => r.apiResult[0] ) 
            .then( person => {
                setFirstName( person.FirstName )
                setLastName ( person.LastName )
            } )
            .catch( e => setError( e.apiText ) ) 
        }
        
        
    }, [api.getToken()] )
    
    const handleLogout = e => {
        
        e.preventDefault();
        
        api.logout();
        
        handleLoginEvent( 'Logged out' );
    }
    
    return <div className="header__user-account">
   
        <img className="header__user-account-avatar" src={PUBLIC_URL + '/images/avatar.svg'} alt="User avatar"/>

        <span>Hello, <span className="header__user-username">{api.isLoggedIn()? ( firstName + ' ' + lastName ): 'login'}</span></span>

        <span className="header__user-account-triangle"></span>
        <ul>
            {api.isLoggedIn()? <li><a href='.' onClick={handleLogout} >Logout</a></li>: ''}
        </ul>
        <div>{false? JSON.stringify(decodeJWT( api.getToken() )): null}</div>
        <div>{error}</div>
    </div>
}



export default AccountMenu;


