import React from 'react'

// import './styles.css';
import {AccountMenu} from "../../lib/iprs-react-library/src/index";

const Header = ({api, handleLoginEvent}) => {

  return (
    <div className="container">
      <header className="header">
        <time className="header__date">
          <span className="header__time"></span>
        </time>
          <AccountMenu api={api} handleLoginEvent={handleLoginEvent} />
          <div className="header__supplementary">
          </div>
      </header>
    </div>
  )
}

export default Header