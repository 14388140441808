import React                              from    'react';
import {PlusButton, MinusButton}                    from '../../util/Buttons';

/** REACT component for designing / configuring a Drop down field on a form. 
 There can be an optional datasource. If this is used, the datasource ID
 is set on the field. When a datasource is set, the updateField
 function will also add the datasources to the webConfig object*/
const DropDownListDesign = ({field, fieldOptions, updateField}) => {

    // find the fieldOption from all the dropdown fieldOptions for this field
    const fieldOption
        = fieldOptions
        ?.filter(fo => fo.length ? true : false)
        ?.map(fo => fo[0])
        ?.find(fo => fo.key === field.name);
    const optionDataSource = fieldOption?.DataSourceID; 
    const fieldDS = field?.DataSourceID;
    const dsOverriden = optionDataSource && (optionDataSource != fieldDS);

    // handle change of the field name (key), required
    const handleChange = e => {
        
        e.stopPropagation();
        
        const newField = {...field};
        if ('keyName' == e.target.name){
            newField.name = e.target.value;
        }else if ('label' == e.target.name){
            newField.text = e.target.value;
        }else { 
            if( e.target.hasOwnProperty('checked') ){
                newField[e.target.name] = e.target.checked;
            }else{
                newField[e.target.name] = e.target.value;
            }
        }
        
        updateField( newField );
    }
    
    /* handle change in the key field which may result in a change in 
     * datasource which may then replace the 
     */
    const handleChangeKey = e => {

        const newField = { ...field };
        newField['name'] = e.target.value;

        const dataSourceID = fieldOptions
            ?.filter(fo => fo.length ? true : false)
            ?.map(fo => fo[0])
            ?.find(fo => fo.key === e.target.value)
            ?.DataSourceID;

        if (dataSourceID) {
            newField['DataSourceID'] = dataSourceID;
        } else {
            newField['DataSourceID'] = 0;
        }

        updateField(newField);
    }

    // swicth between overriding the datasource or not
    const handleDSOverrideChange = e => {

        const newField = { ...field };
        if (!e.target.checked) {
            newField['DataSourceID'] = optionDataSource;
        } else {
            newField['DataSourceID'] = 0;
        }

        updateField(newField);
    }

    return <div>
    
        <div className={'form__field form__field--full-width'} >
            <label class="form__label form__label--auto-width form__label--margin-x" htmlFor={'label'}>
                Label
            </label>
            <input name='label' value={field.text} onChange={handleChange} className='form__input form__input form__input--mobile-y-bottom'/>
        </div>
        
        <div className={'form__field form__field--full-width'} >
            <label htmlFor='name'>Key Name</label>
            <select name='name' value={field.name} onChange={handleChangeKey} >
                {!fieldOption && <option value='__no_val__' key='__no_val__' >Select</option>}
            { fieldOptions
                    ?.filter( fo => fo.length? true: false )
                    ?.map( fo => fo[0] ) // text field has no sub fields
                    ?.map( fo => <option value={fo.key} >{fo.defaultText} ({fo.key})</option> )
            }
            </select>
            
        </div>

        {
            optionDataSource &&
            <div className={'form__field form__field--full-width'} >
                <label htmlFor='DSOverride' >Override Datasource</label>
                <input name='DSOverride' checked={dsOverriden} onChange={handleDSOverrideChange} type='checkbox' className="form__checkbox-input" />
            </div>
        }

        {(!optionDataSource || dsOverriden) && <>
            <div className={'form__field form__field--full-width'} >
                <FieldList field={field} updateField={updateField} />
            </div>
        
            <div className={'form__field form__field--full-width'} >
                <label class="form__label form__label--auto-width form__label--margin-x" htmlFor='otherOption' >
                    Other Option (Leave blank unless you want the user to be able to add an option)
                </label>
                <input name='otherOption' value={field.otherOption} onChange={handleChange} className='form__input form__input form__input--mobile-y-bottom'/>
            </div>
        </>
        }
        
        <div className={'form__field form__field--full-width'} >
            <label htmlFor='required' >Required</label>
            <input name='required' checked={field.required} onChange={handleChange} type='checkbox' className="form__checkbox-input" />
        </div>
    </div>
};

/** REACT component to edit a list of drop down options.  */
const FieldList = props => {
    
    const { field, updateField  }               = props;
    const { Options = [] }                      = field;
    
    const handleOptionChange = (index, newOption) => {
        
        const newField              = {...field};
        const newOptions            = [...Options];
        newOptions.splice(index, 1, newOption);
        
        newField.Options            = newOptions;
        
        updateField( newField );
    }
    
    const handleAdd = e => {
        
        const newField              = {...field};
        const newOption = { Text: 'Change Me', Value: 'change-me' }
        
        const newOptions            = [...Options, newOption];
        newField.Options            = newOptions;
        
        updateField( newField );
    }
    
    const handleDelete = (index) => {
        
        const newField              = {...field};
        const newOptions            = [...Options];
        newOptions.splice(index, 1);
        
        newField.Options            = newOptions;
        
        updateField( newField );
    }
    
    return <ul>
        {Options?.map( (item, index) => <FieldOption key={index} index={index} option={item}  handleOptionChange={handleOptionChange} handleDelete={handleDelete}/> )}
        <li><PlusButton onClick={handleAdd} /></li>
    </ul>
};

const FieldOption = ({index, option, handleOptionChange, handleDelete}) => {

    const { Text, Value } = option

    const handleChange = e => {
        
        e.preventDefault();
        
        const newOption = {...option};
        
        newOption[ e.target.name ] = e.target.value;
        
        handleOptionChange(index, newOption);
    }

    return <li className={'form__field form__field--full-width'} >
        <label className="form__label" htmlFor=''>
            Display text
        </label>

        <input name='Text' type="text" onChange={handleChange} value={Text} />

        <label class="form__label form__label--auto-width form__label--margin-x" htmlFor='Value' >
            Submit value
        </label>

        <input name='Value' type="text" onChange={handleChange} value={Value} />
        
        <MinusButton onClick={e=>handleDelete(index)} />
    </li>
}

export default DropDownListDesign;