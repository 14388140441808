import { LoadingIndicator, TwoSwitch }          from "../lib/iprs-react-library/src/index.js";
import ContactInformation                       from "./ContactInformation";
import sanitizeHtml         from 'sanitize-html';
import { useEffect, useState }                  from "react";

const clientDomainAndNameFilter = new RegExp(process.env.REACT_APP_CLIENT_NAME_AND_DOMAIN_FILTER ?? ".*");
const domain = window.location.hostname;

const ReferralClientList = ({api, selectReferrer}) => {

	const [err, setErr] = useState(null);

    const [referrerDetails, setReferrerDetails] = useState(null);

    useEffect(()=>{

        // we do receive duplicate party IDs
        const removeDuplicateParties = clients =>
            clients.reduce((acc, curr) => {
                if(!acc.ids.includes(curr.PartyID)){
                    return { ids: [...acc.ids,curr.PartyID], result: [...acc.result, curr] };
                }else{
                    return acc;
                }
            }, { ids: [], result: [] } ).result;

        api.transact("GetReferrerDetails", {})
            .then(r => r.apiResult)
            .then(r => r.ClientList)
            .then(r => r.filter(c => clientDomainAndNameFilter.test(`domain='${domain}' client='${c.Name}'`)))
            .then(r => removeDuplicateParties(r) )
            .then(r => setReferrerDetails(r))
            .catch(err => setErr(err.message));
    }, [api, setReferrerDetails]);
    
    const getWelcomeText = () => {
        /* This replaces <div ng-bind-html="trustHTML(ClientList[0].WelcomeText)"></div> in the 
        angular version of this site. It works when the referrer has a limited set of clients
        who all share the same welcome text */
        const welcomeText = referrerDetails?.find(r => ('string' === typeof r?.WelcomeText) && 0 < r?.WelcomeText.trim().length )?.WelcomeText;
        return welcomeText? sanitizeHtml(welcomeText) : null;
    };
    const welcomeText = getWelcomeText();

    return <>
        <TwoSwitch test={null === referrerDetails} >
            <LoadingIndicator loading={!err} />
            <TwoSwitch test={0 < referrerDetails?.length} >
                <div style={{display: 'contents'}}>
                    {welcomeText && <div 
                    style={{fontWeight: 'bold', marginBottom: '3em', marginTop: '1em'}} 
                    dangerouslySetInnerHTML={{ __html: welcomeText }} /> }
                    <ul className="referrerListStyle" >
                        {referrerDetails?.map(r => <Li clientReferralDetails={r} 
                        key={r.PartyID} selectReferrer={selectReferrer}/>)}
                    </ul>
                </div>
                <p>Sorry there are no IPRS clients for whome you Can
                    submit referrals.
                </p>
            </TwoSwitch>
        </TwoSwitch>
        <TwoSwitch test={err} >
            {err}
        </TwoSwitch>

        <ContactInformation />
    </>;
}

const Li = ({clientReferralDetails, selectReferrer}) => {
    return <li>
            <a href='' 
            referrer-name={clientReferralDetails.Name}
            name={clientReferralDetails.Name}
            value={clientReferralDetails.PartyID}
            onClick={e => {e.preventDefault(); selectReferrer(clientReferralDetails)}}>{clientReferralDetails.Name}</a>
        </li>
}

export default ReferralClientList; 