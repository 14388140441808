import React from 'react'

const Footer = () => {
  return (
    <div class="container">
        <div class="typography">
            <footer className="footer">
                <p>
                    {"© 2024 IPRS Health Limited, Speed Medical House, Matrix Park, Chorley, Lancashire, PR7 7NA"}
                    <span className="footer__delimiter">|</span>
                    <a 
                      style={{fontSize: '12px'}} 
                      href="https://www.iprshealth.com/about-us/gdpr/iprs-health-privacy-statement/" 
                      target="_blank" 
                      rel="noreferrer"
                      title="Link to Privacy Policy Page" 
                    >Privacy Policy</a>
                </p>
            </footer>
        </div>
    </div>
  )
}

export default Footer