import React, { useState }      from 'react';
import { useCallback }          from 'react';
import { useEffect }            from 'react';
import toBoolean                from '../util/toBoolean';
import getChangeHandler from './FormFunctions/validationChangeHandler';

import UnvalidatedForm          from './UnvalidatedForm';

const ValidatedForm = ({ validating, fieldDefinitions, formFieldData,
    setFormFieldData, setDataIsValid, setFormTouched, api,
    createOptionalOverrideFieldComponent, objectIndex, setInvalidFields }) => {

    if (!(Array.isArray(fieldDefinitions))) {
        throw new Error('fieldDefinitions is not an array');
    }
    if (!setFormFieldData) {
        throw new Error('setFormFieldData is not set');
    }
    if (!setDataIsValid) {
        throw new Error('setDataIsValid is not set');
    }
    if (false !== validating && true !== validating) {
        throw new Error('Validating must be a boolean');
    }

    const fieldDefinitionsWithoutStaticData = 
    fieldDefinitions.filter(field => field.controlType?.toLowerCase() !== 'hidden');

    // a length of parms that is an offence to software architecture
    const handleChange = useCallback(getChangeHandler(fieldDefinitions, formFieldData, setFormFieldData, setDataIsValid, objectIndex, setInvalidFields), 
        [fieldDefinitions, formFieldData, setFormFieldData, setDataIsValid, objectIndex, setInvalidFields]);

    return <>
        <UnvalidatedForm 
            fieldDefinitions={fieldDefinitionsWithoutStaticData}
            formFieldData={formFieldData}
            onChange={handleChange} validating={validating} api={api}
            objectIndex={objectIndex}
            createOptionalOverrideFieldComponent={createOptionalOverrideFieldComponent} />
    </>
};

export default ValidatedForm;
