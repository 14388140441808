import { LoadingIndicator, TwoSwitch } from "../../lib/iprs-react-library/src/index.js";
import { useEffect, useState } from "react";
import AddReferrerForm from "./AddReferrerForm";

const AddReferrer = ({ api, urlToken }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const [clientList, setClientList] = useState([]);

    useEffect(() => {
        setLoading(true);
        api.transact("AvailableClientsFromToken", { token: urlToken })
            .then(r => r.apiResult)
            .then(r => {
                setError(null);
                setLoading(false);
                setClientList(r);
            })
            .catch(e => {
                setError(e.message);
                setLoading(false);
            });
    }, [api, urlToken]);

    return (
        <div className="main__section">
            <div className="container typography">
                <LoadingIndicator loading={loading} />
                <TwoSwitch test={error}>
                    <p>
                        You can only use this form when you access it via a URL,
                        containing a valid Token. For example
                        https://iprshealthreferrals.iprsapps.com/AddReferrer.html?token=eyJ0eXAiOiJKV...
                        . <br />
                        We have not been able to verify your token. <br />
                        Error response: {error}
                    </p>
                    <AddReferrerForm
                        api={api}
                        clientList={clientList}
                        setError={setError}
                        urlToken={urlToken}
                        setLoading={setLoading}
                    />
                </TwoSwitch>
            </div>
        </div>
    );
};

export default AddReferrer;
