import React, { useCallback, useState } from "react";
import { OKCancel } from "../util/Buttons";
import TwoSwitch from "../util/TwoSwitch";
import PasswordResetRequestForm from "./PasswordResetRequestForm";
import LoadingIndicator  from "../util/LoadingIndicator";

/*
 * REACT component to show a login for which overlays the diasplay
 */
const LoginOverlay = ({ handleLoginEvent, api, children }) => {
    const childComponents = { ...defaultChildren, ...children };

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [mfaToken, setMfaToken] = useState("");

    const [loading, setLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);
    const [resetPendingMessage, setResetPendingMessage] = useState(false);

    const [showPasswordResetRequest, setShowPasswordResetRequest] =
        useState(false);

    const [mfaRequested, setMfaRequested] = useState(false);

const doLogin = useCallback((sendMFA) => {
    setLoading(true);
    const mfaTokenToSend = sendMFA ? mfaToken : null;
    api.login(username, password, mfaTokenToSend)
        .then(r => handleLoginEvent("Logged in"))
        .catch(err => {
            setLoading(false);
            setErrorMessage(err.message);
            setResetPendingMessage(false);
            
            if (err.message === "MFA Required") {
                setMfaRequested(true);
                setErrorMessage(null);
            }
        });
}, [api, handleLoginEvent, mfaToken, password, username]);

    const handleOK = (e = null) => {
        doLogin(true);
    };

    const handleResentMFA = e => {
        e.preventDefault();
        doLogin(false);
        setMfaToken("");
        setMfaRequested(false);
    }

    const handlePasswordResetRequestDone = () => {
        setShowPasswordResetRequest(false);
        setResetPendingMessage(true);
        setErrorMessage(null);
    };

    const handleCancelResetRequest = () => {
        setShowPasswordResetRequest(false);
        setResetPendingMessage(false);
        setErrorMessage(null);
    };

    const handleResetRequest = e => {
        e.preventDefault();
        setShowPasswordResetRequest(true);
        setErrorMessage(null);
    };

    return (
        <div className="loginOverlay">
            <TwoSwitch test={showPasswordResetRequest}>
                <PasswordResetRequestForm
                    done={handlePasswordResetRequestDone}
                    onCancel={handleCancelResetRequest}
                    api={api}
                />
                <>
                    <form>
                        <label htmlFor="username" className="form__label">
                            {childComponents.usernameLabel}
                        </label>
                        <input
                            data-testid="username"
                            id="username"
                            className="form__field"
                            autoComplete="username"
                            onChange={e => setUsername(e.target.value)}
                            value={username}
                        />

                        <label htmlFor="password" className="form__label">
                            {childComponents.passwordLabel}
                        </label>
                        <input
                            id="password"
                            data-testid="password"
                            className="form__field"
                            type="password"
                            onChange={e => setPassword(e.target.value)}
                            value={password}
                            autoComplete="current-password"
                        />

                        <TwoSwitch test={mfaRequested}>
                            <fieldset className="form__fieldset">
                                <legend>
                                    {childComponents.mfaTokenLabel}
                                </legend>
                                <label htmlFor="MFAToken" className="form__label">
                                    MFA Token
                                </label>
                                <input
                                    type="text"
                                    onChange={e => setMfaToken(e.target.value)}
                                    value={mfaToken}
                                    id="MFAToken"
                                    className="form__field"
                                    placeholder="MFA Token"
                                    autoComplete="one-time-code"
                                />
                                <div><a href="" onClick={handleResentMFA} >Resend code</a></div>
                            </fieldset>
                        </TwoSwitch>

                        <OKCancel
                            onOK={handleOK}
                            okText="Login"
                            onCancel={e => setErrorMessage(null)}
                        />

                        <LoadingIndicator loading={loading} />

                        <legend>
                            <TwoSwitch test={resetPendingMessage}>
                                {childComponents.resetPendingMessage}
                                {childComponents.legend}
                            </TwoSwitch>
                        </legend>
                    </form>
                    <p>click here to <a 
                        href=""
                        className="forgottenPassword"
                        tabIndex={0}
                        onClick={handleResetRequest}>reset your password</a>.</p>
                    <p>{errorMessage}</p>
                </>
            </TwoSwitch>
        </div>
    );
};

const defaultChildren = {
    legend: "Login with your Pharos username and password. Password resets can be done through Pharos.",
    resetPendingMessage:
        "Please check your email inbox for a password reset email",
    usernameLabel: "Username",
    passwordLabel: "Password",
    mfaTokenLabel: "We've sent you a one time token, please enter it here",
    mfaTokenLegend: "One time token",
};

export default LoginOverlay;
