import toBoolean from "../../util/toBoolean";
import { validate } from "./validationFunctions";

const toInt = possibleInt => {
    let result  = Number.parseInt(possibleInt);
    result      = Number.isInteger(result)? result: 0;
    return result;
}

/** 
 * Used by Tabbed Form and Validated form to update 
 * the correct field in the newFieldData.
*/
const setValueForChange = (newFieldData, e, objectIndexP) => {

    const { name, value, checked, type,
        noFormEnable = false } = e.target;
    const fieldDefinition = e.fieldDefinition;
    const useArray = toBoolean( fieldDefinition.InjurySpecific );

    const objectIndex = toInt(objectIndexP); 

    const valueToSend = (typeof value !== 'undefined') ?
        value : toBoolean(checked, false);

    const object = fieldDefinition.object;
    if (typeof object !== 'undefined') {
        if (typeof newFieldData[object] === 'undefined'){
            newFieldData[object] = useArray? []: {};
        }
        if(useArray){
            const array = newFieldData[object];
            if(!Array.isArray(array)){
                if(undefined === newFieldData[object]){
                    newFieldData[object] = [];
                }else{
                    newFieldData[object] = [newFieldData[object]];
                }
            }else{
                if(!array[objectIndex]){
                    array[objectIndex] = {};
                }
            }
            newFieldData[object][objectIndex][name] = valueToSend;
        }else{
            newFieldData[object][name] = valueToSend;
        }
    } else {
        newFieldData[name] = valueToSend;
    }

    return newFieldData;
}

/**
 * Used by Tabbed Form and Validated form to do validation.
 * Returns an event handler which handles 
 * updates to form fields. This handler will validate field values and pass on an
 * update to setFormFieldData. It also send back an update to setDataIsValid to
 * say whether the field values are collectively valid input.
 * Note the objectIndex, is problematic when we come to have different sub tabs
 * in forms, because it may not be the same accross all top level tabs. 
 * @param {function} fieldDefinitions 
 * @param {Object} formFieldData The values in all the fields.
 * @param {function} setFormFieldData A callback to pass on changes to field data. 
 * @param {function} setDataIsValid A callback to say whether all fields are valid
 * @param {BigInteger} objectIndex The index into tabs in a complex table
 * @param {function} setInvalidFields Callback to provide an array of invalid fields
 * @param {function} setInvalidTabs Callback to provide an update to the list of invalid tabs 
 * @returns void
 */
const getChangeHandler = (fieldDefinitions, formFieldData, setFormFieldData, setDataIsValid, objectIndex, setInvalidFields, setInvalidTabs) => e => {

    const { name, value, 
        noFormEnable = false } = e.target;

    const fieldDefinitionsWithoutStaticData = 
        fieldDefinitions.filter(field => field.controlType?.toLowerCase() !== 'hidden');

    let newFieldData = { ...formFieldData };

    if ('allFields' === name) {
        e?.preventDefault();
        // we are setting multiple fields at the same time 
        //const clone = { ...formFieldData, ...value };
        newFieldData = { ...newFieldData, ...value };
        //doEnableForm = true;
    } else {

        newFieldData = setValueForChange(newFieldData, e, objectIndex);
    }

    const staticFormData = {};
    fieldDefinitions.forEach(field => { if (field.controlType?.toLowerCase() === 'hidden') { staticFormData[field.name] = field.value } });

    const formValid =
        fieldDefinitionsWithoutStaticData?.reduce((accumulator, currentValue) => accumulator && validate(currentValue, newFieldData, objectIndex), true);

    setDataIsValid?.(formValid);

    const invalidFieldsWithTabNames = formValid? []:
        fieldDefinitionsWithoutStaticData?.reduce((accumulator, currentValue) => 
        validate(currentValue, newFieldData, objectIndex)? accumulator: [...accumulator, ({name:currentValue.name, tabname:currentValue.__tabname})], 
        []);
    const invalidFields = invalidFieldsWithTabNames.map( f => f.name    );
    const invalidTabs   = invalidFieldsWithTabNames.map( f => f.tabname );
    setInvalidTabs?.(invalidTabs);
    setInvalidFields?.(invalidFields);

    newFieldData = { ...newFieldData, ...staticFormData };
    
    setFormFieldData(newFieldData);

};

export default getChangeHandler;