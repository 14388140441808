import { TwoSwitch } from "../../lib/iprs-react-library/src/index.js";
import React, { useEffect, useState }                                        from "react";


/**
 * This component is added here, to override the default TimeTextBox component for 
 * just Aeromed holding rooms currently. This should be fixed in the library, along
 * with validation code.
 * @param {*} props 
 * @returns 
 */
const TimeTextBox = props => {

  const { name, text, handleChange, validating, 
            required, values,
            RelativeTo          = null,
            validationWarning,
            referralInputError
        } = props;

    const value             =   props.getOwnFieldValue();
    const relativeDate      =   values?.[RelativeTo] ??         null;
    const parentValueSt     =   value??                         paddDateWithEmptyTime(relativeDate, new Date());
    const min               =   paddDateWithEmptyTime(relativeDate, new Date(0));

    const validationAlert   =   required && validating && (!value || (relativeDate && (min >= parentValueSt)));
    const className         =   'form__input' + (validationAlert? ' input-validate-highlight': '');  

    // use internal state to avoid re-rendering the parent component
    const [internalValue, setInternalValue] = useState(parentValueSt);
    useEffect(() => {
        setInternalValue(parentValueSt);
    }, [parentValueSt]);

    return <div>
        <div className="form__field form__field--full-width">
        <label className="form__label">
            {text}
            {required && <span style={{ color: 'red' }}> *</span>}
        </label>
        <input type='datetime-local' name={name} value={internalValue} 
            onBlur={e=>{handleChange({target: {name, value: internalValue}});return true}} 
            onChange={e=>setInternalValue(e.target.value)} required={required} min={min} 
        className={className} 
            pattern='[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}'
            size={16} />
        <TwoSwitch test={validationAlert && ( validationWarning || referralInputError)} >
            <div className="alert required">{validationWarning?? referralInputError}</div>
        </TwoSwitch>
    </div></div>
}

const isDateString = str => {
    const dateRegex = /^\d\d\d\d-\d\d-\d\d$/;
    const result = dateRegex.test(('' + str).trim());

    return result;
}

const dateTimeRegex = /^\d\d\d\d-\d\d-\d\dT(\d\d:\d\d)$/;

const isDateTimeString = str => {
    const result = dateTimeRegex.test(('' + str).trim());
    return result;
}

const paddDateWithEmptyTime = ( dateString, defaultDate = new Date()) => {
    let result;
    if(isDateString(dateString)){
        result = dateString + 'T00:00';
    }else if (isDateTimeString(dateString)){
        result = dateString;
    }else{ 
        result = defaultDate.toISOString().substring(0, 16);
    }
    
    return result;
}

export default TimeTextBox;
