const filterDataForForm = (fieldFormData, fieldDefinitions) => {

    if (null === fieldFormData  || (typeof fieldFormData !== 'object')) {
        throw new Error('fieldFormData must be an object');
    }

    if (null === fieldDefinitions || (!Array.isArray( fieldDefinitions ))) {
        throw new Error('fieldDefinitions must be an array');

    }

    const fieldNames = fieldDefinitions.map(field=>field.name);
    const result = {};

    Object.entries(fieldFormData)
        .filter(entry => fieldNames.includes( entry[0] ))
        .forEach(entry => result[entry[0]] = entry[1]);

    return result;
};

/* the object index is the index into the field data array.
    E.g. if the field definitions have field1 and field2, both with object 
    myObject and the field index is 0 then the formFieldData would look like:-
    { myObject: [{ field1: "val1", field2: "val2" }] }
*/
const getFieldData = (formFieldData, fieldDefinition, objectIndex = 0) => {

    const fieldName = fieldDefinition.name;
    const object    = fieldDefinition.object?? fieldDefinition.Object;

    const getDataFromArray  = 
        fieldObjectArray => Array.isArray(fieldObjectArray)? fieldObjectArray[ objectIndex ]?? {}: fieldObjectArray;

    const result    = object ?
        getDataFromArray( formFieldData[object] )?.[fieldName] ?? undefined:
        formFieldData[fieldName]?? undefined;
    return result;
}

export default filterDataForForm;
export {getFieldData};