import React        from "react";
import { useState, useEffect } from "react";


/* 
 * React component to display a simple edit box or input field. This also supports
 * checkboxes.
 */
const Slider = (props) => {
    
    const {name, text = '', disabled, controltype, min = '0', max = '100',
        validating, handleChange: parentHandleChange, required,
        regex, validationWarning, autocomplete, value: defaultValue = 0} = props;
    
    console.log(props)

    // const [sliderValue, setSliderValue] = useState(value)

    // useEffect(()=>{
    //     setSliderValue(value)
    // },[value])

    // console.log(sliderValue)

    const value = props.getOwnFieldValue() ?? defaultValue;

    // if (value == 0) {
    //     setSliderValue(props.getOwnFieldValue());
    // }
    
    const [error, setError] = useState(null);
    
    let className = 'form__slider';

    if(validating){
        if( required && !validate( regex, value ) ){
            className += ' input-validate-highlight';
        }
    }
    
    const handleBlur = e => {
        
        const valid = validate( regex, value );
        
        setError( valid? null: validationWarning );
    }
    
    const handleChange = e => parentHandleChange({target: {name: e.target.name, value: e.target.value}});

    const boxProps = {name, value, disabled, autoComplete: autocomplete, className, 
        onChange: handleChange, onBlur: handleBlur,
        'data-testid': name, id: name, type: 'range', min, max
    };

    return <div>
        <div className="form__field form__field--full-width form__slider" >
            <label htmlFor={name} className="form__label">
                {text}
                {required && <span style={{color: 'red'}}> *</span>}
            </label>

            {React.createElement(
                'input',
                boxProps)}
            
            <p className="form__slider__value">{value} %</p>

        </div>
        {error? 
        <div className="form__field form__field--full-width">
            <div className="form__label "></div>
            <div style={{ color: 'red', width: '100%' }}>{error}</div>
        </div>:
        ''
        }
    </div>
};

const validate = ( regex, value ) => {
    
    let result = false;
    if ( regex ){
        try{
            const regExp = new RegExp( regex );
            if( value && regExp.test( value ) ){
                result = true;
            }
        }catch(e){
            console.log( 'Bad regular expression \'' + regex + '\'' );
        }
    }else if( value ){ 
        result = true;
    }
    
    return result;
}

export default Slider;