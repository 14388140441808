
const safeEval = (code, thisValue = null, values) => {

  const codeSt = (code ?? '');
  const codeToUseSt = codeSt.length ? codeSt : 'true';
  const funtionCode = '"use strict";return ' + codeToUseSt;

  // supports IF='$scope.Answers' which we have in much existing for description
  const scope = { Answers: values, answers: values };

  let result = false;
  try{
    const fun = new Function('$scope', '$Scope', 'answers', 'Answers', 'values', 'alert', 'fetch', 'window', funtionCode);
    result = fun.apply(thisValue, [scope, scope, values, values, values, null, null, null]);
  }catch (e) {
      console.log('Exception in evaluating code', e);
  }

  return true === result;
}

export default safeEval;
