import React        from "react";
import { useState, useEffect } from "react";


/* 
 * React component to display a simple edit box or input field. This also supports
 * checkboxes.
 */
const TextBox = (props) => {
    
    const {name, text = '', disabled, cols: colsSt = 20, rows: rowsSt=1, controltype, 
        validating, handleChange: parentHandleChange, required,
        regex, validationWarning, autocomplete, value: defaultValue = '', customcssclass } = props;

    const value = props.getOwnFieldValue() ?? defaultValue;
    
    const cols = Math.max( Number.parseInt( colsSt ), 1 )?? 1;
    const rows = Math.max( Number.parseInt( rowsSt ), 1 )?? 1;
    const multiline = rows > 1;

    let className = multiline? 'form__textarea': 'form__input';
    const valid = validating? validate( regex, value, required ): true;
    if(!valid){
        className += ' input-validate-highlight';
    }
    
    const handleChange = e => parentHandleChange({target: {name: e.target.name, value: e.target.value}});

    const boxProps = {name, value, disabled, autoComplete: autocomplete, className,
        onChange: handleChange, rows, cols, 
        'data-testid': name, id: name
    };
    
    if(['email', 'tel', 'color', 'number', 'password', 'range', 'url'].includes(controltype)){
        boxProps.type = controltype;
    }else{
        boxProps.type = 'text';
    }
    if (regex){
        boxProps.pattern = regex; 
    }

    let customClass = customcssclass === undefined ? "" : " " + customcssclass;

    return <div>
        <div className={"form__field form__field--full-width" + customClass } >
            <label htmlFor={name} className="form__label">
                {text}
                {required && <span style={{color: 'red'}}> *</span>}
            </label>

            {React.createElement(
                multiline? 'textarea': 'input',
                boxProps)}

        </div>

        {!valid ? <div className="alert required">{validationWarning}</div> : <></>}

    </div>
};

// we should be using the central version of this
const validate = ( regex, value, required ) => {
    
    let result = false;
    if(!required && !regex){
        result = true;
    } else if (!required && !value) {
        result = true;
    }else if (required && value && !regex){
        result = true;
    }else if ( regex ){
        try{
            const regExp = new RegExp( regex );
            result = regExp.test( value );
        }catch(e){
            console.log( 'Bad regular expression \'' + regex + '\'' );
        }
    }
    
    return result;
}

export default TextBox;

