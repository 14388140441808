import React                                        from 'react';
import TwoSwitch                                    from '../../util/TwoSwitch';

const TimeTextBox = props => {

  const { name, text, handleChange, validating, 
            required, values,
            RelativeTo          = null,
            validationWarning,
            referralInputError
        } = props;

    const value             =   props.getOwnFieldValue();
    const relativeDate      =   values?.[RelativeTo] ??         null;
    const parentValueSt     =   value??                         paddDateWithEmptyTime(relativeDate, new Date());
    const min               =   paddDateWithEmptyTime(relativeDate);

    const validationAlert   =   required && validating && (!value || (relativeDate && (min >= parentValueSt)));
    const className         =   'form__input' + (validationAlert? ' input-validate-highlight': '');  

    return <div>
        <div className="form__field form__field--full-width">
        <label className="form__label" htmlFor={name}>
            {text}
            {required && <span style={{ color: 'red' }}> *</span>}
        </label>
        <input type='datetime-local' name={name} id={name} value={parentValueSt} onChange={handleChange} required={required} min={min} 
        className={className} 
            pattern='[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}'
            size={16} />
        <TwoSwitch test={validationAlert && ( validationWarning || referralInputError)} >
            <div className="alert required">{validationWarning?? referralInputError}</div>
        </TwoSwitch>
    </div></div>
}

const isDateString = str => {
    const dateRegex = /^\d\d\d\d-\d\d-\d\d$/;
    const result = dateRegex.test(('' + str).trim());

    return result;
}

const dateTimeRegex = /^\d\d\d\d-\d\d-\d\dT(\d\d:\d\d)$/;

const isDateTimeString = str => {
    const result = dateTimeRegex.test(('' + str).trim());
    return result;
}

const paddDateWithEmptyTime = ( dateString, defaultDate = new Date()) => {
    let result;
    if(isDateString(dateString)){
        result = dateString + 'T' + '00:00';
    }else if (isDateTimeString(dateString)){
        result = dateString;
    }else{ 
        result = defaultDate.toISOString().substring(0, 16);
    }
    
    return result;
}

export default TimeTextBox;
