
import safeEval                         from '../Eval/EvalFunction';

/**
 * 
 * @param {Object} f A filed or Tab definition which may contain an 'if' key
 * @param {Object} values The current set of form data which may be referenced 
 * by any 'if' statement.
 * @returns {boolean} false if the object defined by parameter f has an if
 * parameter that evalueates to false. Otherwise true.
 */
const testFieldOrTabIfEvaluatingFalse = (f, values) => {
    let result = true;
    if (f.hasOwnProperty('if')) {
        const fieldName = f.name;
        const thisValue = values[fieldName];
        result = safeEval(f.if, thisValue, values);
    }
    return result;
}

export default testFieldOrTabIfEvaluatingFalse;