import toBoolean from '../../util/toBoolean';
import fixAutocomplete from './FixAutocomplete';

// Ensure that Options and options are outputted just as options.
const handleOptions = (fieldDescription) => {

    const options = Array.isArray(fieldDescription.Options)? fieldDescription.Options : 
        (Array.isArray(fieldDescription.options)? fieldDescription.options : null);
    
    const result = {...fieldDescription};
    if(Array.isArray(options)){
        delete (result.options);
        delete (result.Options);
        result.options = options;
    }
    return result;
}

/**
 * Takes a field description and normalises so that all
 * fields are in Canonical form.
 * E.G. ControlType becomes controlType and it's value
 * is lower case
 * */
const replicateAllFieldsToLowerCase = _fieldDescription => {

    if (typeof _fieldDescription !== 'object') {
        return _fieldDescription;
    }
    const fieldDescription = handleOptions(_fieldDescription);

    const result = {};
    Object.keys(fieldDescription).forEach(key => {
        const value = fieldDescription[key];
        const lowerKey = key.toString().trim().toLowerCase();

        let useValue;
        if ('required' === lowerKey) {
            useValue = toBoolean(value);
        } else if ('enabled' === lowerKey) {
            useValue = toBoolean(value, true);
        } else if ('injuryspecific' === lowerKey) {
            useValue = toBoolean(value, false);
        } else if ('controltype' === lowerKey) {
            useValue = value.toLowerCase();
        } else if ('autocomplete' === lowerKey) {
            useValue = fixAutocomplete(value);
        } else {
            useValue = value;
        }

        if (coreFields.includes(lowerKey)) {
            result[lowerKey] = useValue;
        } else {
            result[lowerKey] = useValue;
            result[key] = useValue;
        }
    });
    return result;
};

const coreFields = ['controltype', 'name', 'text', 'required', 'enabled', 
    'options', 'autocomplete', 'object', 'InjurySpecific'];

export default replicateAllFieldsToLowerCase;