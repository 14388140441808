import React, {useState}                             from    'react';

/* 
 * React component to display a drop down box. Uses an HTML select.
 */
const DropDownList = props => {
    
    const {name, text, handleChange, width, options: items = [],
        otherOption, validating, required, autocomplete, validationWarning} = props;
    
    const value = props.getOwnFieldValue();
    
    const [otherInputValue, setOtherInputValue] = useState('');
    
    const otherInputValueSet = otherInputValue? true: false;
    const otherOptionOn      = otherOption?     true: false;
    
    const showOtherInList    = otherOptionOn && otherInputValueSet && !(items.map( i => i.Value ).includes( value ));
    const showOtherInput     = value === '__other_special_value';
    
    const widthInt = Number.parseInt( width );
    let wrapperClass;
    if(100 <= widthInt && 200 > widthInt){
        wrapperClass = 'form__dropdown-wrapper form__dropdown-wrapper--width-100';
    }else{
        wrapperClass = 'form__dropdown-wrapper ';
    }
    
    let className = 'form__dropdown ';
    if(validating){
        if(required){
            let valid;
            // if isFieldBool is true, the field definition is for a boolean 
            // field. I.e. the field has two possible values true or false
            // which might display as yes / no or similar
            const isFieldBool = props?.valuetype?.toLowerCase() === 'bool';
            if(isFieldBool){
               valid = (value === 'true' || value === 'false' || 
                value === true || value === false);
            }else{
                valid = (value && value !== '0');  
            }
            if(!valid){
                className += ' input-validate-highlight';
            }
        }
    }

    return  <div> 
            <div className='form__field form__field--full-width' >
                <label className="form__label" htmlFor={name} >
                    {text}
                    {required && <span style={{color: 'red'}}> *</span>}
                </label>
                <div className={wrapperClass} >
                <select     className={className}
                            name={name}
                            data-testid = {name}
                            id = {name}
                            onChange={handleChange}
                            value={value}
                            autoComplete={ autocomplete} >
                            
                        <option key={0} value={0} className='form__mock-dropdown-option' >{'Select'}</option>
                        { showOtherInList && <option key={otherInputValue} value={otherInputValue} className='form__mock-dropdown-option' >{otherInputValue}</option> }
                        <Options items={items} />
                        { otherOption &&     <option key='__other_special_value' value='__other_special_value' className='form__mock-dropdown-option' >{otherOption}</option> }
                    </select>
                </div>
            </div>
            
            {showOtherInput && <div className='form__field form__field--full-width' ><div className='form__field form__field--full-width' >
            <label className="form__label" htmlFor={name} for={ name } >
                    {otherOption}
                    {required && <span style={{color: 'red'}}> *</span>}
                </label>
                <input  name={name} 
                        data-testid = {'other'+name}
                        id = {'other'+name}
                        value={otherInputValue} 
                        placeholder={'Enter ' + otherOption}
                        onChange={e=>setOtherInputValue(e.target.value)} 
                        onBlur={handleChange}
                        autoComplete={ autocomplete} />
            </div></div> }
            {value === '0' ? <div class="alert required">{validationWarning}</div> : <></>}
        </div>
}
 
const Options = ({items}) => {

    const deDupedItems = filterDuplicates(items); 
    return deDupedItems.map(i=>(<option key={i.Value} value={i.Value} data-testid={i.Value} className='form__mock-dropdown-option' >{i.Text}</option>));
}

const filterDuplicates = items => {
    const itemNames = {};
    const newItems = [];
    items.forEach(i=>{
        const name = i.Value;
        if(!itemNames.hasOwnProperty(name)){
            newItems.push({...i});
        }
        itemNames[name] = null; // marker
    });
    return newItems;
}

export default DropDownList;
