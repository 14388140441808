
import React, {useState, useEffect}     from    'react'

const FormInput = ({name, id, type='text', 
    className='form__input form__input--mobile-y-bottom', maxLength,
    placeHolder = '', onChange, onBlur = (n,v) => {}, validate, requiredNames = [], 
    values={} }) => {
    
    const value = values?.[name]?? '';
    
    const required = requiredNames.includes(name);
    
    const handleChange = e => onChange(name, e.target.value);
    
    const handleBlur = e => onBlur(name, e.target.value);
    
    let classNm = className
    if(validate){
        if( required && !value ){
            classNm += ' input-validate-highlight'
        }
    }
    
    return (
      <input id={id} data-testid={name} type={type} className={classNm}
         name={name} value={value} onChange={handleChange} onBlur={handleBlur}
        maxLength={maxLength} placeholder={placeHolder}  />)
} 

export default FormInput
