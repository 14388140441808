
/**
 * 
 * @param {*} param0 
 * @returns 
 */
const FormList = ({forms, clientTitle, onCancel, formSelect}) => {

    return <>
        <button className="backButton" onClick={e=>{e.preventDefault();onCancel()}}>{'< Back'}</button>
        <hr className="backButton"/>
        <h1>{clientTitle}</h1>
        {forms?.map((form) => <li
            key={form.ConfigTitle}
            onClick={e=>{e.preventDefault();formSelect(form.ConfigTitle)}}
            style={{cursor: 'pointer'}}
            data-value={form.ConfigTitle}
        >{form.ConfigTitle}</li>)}
    </>
} 

export default FormList;