import { LoadingIndicator, TwoSwitch }  from "../lib/iprs-react-library/src/index.js";
import { useEffect, useState }          from "react";
import FormList                         from "./FormList";
import ReferralFormOrThanks             from "./ReferralFormOrThanks";
import { PROG_STATE_SHOW_FORM } from "./ProgressBar";

/**
 * Component to show either a form, or links to select a form.
 * This calls the API to get the form(s) for the client and
 * will only show a list if there are more than one.
 * @param {object} props  
 * @returns 
 */
const FormOrFormSelect = ({api, clientID, clientTitle, onCancel, setProgressState}) => {

	const [err, setErr]                     = useState(null);

    const [forms, setForms]                 = useState(null);

    const [selectedFormTitle, setSelectedFormTitle]   = useState(null);

    const formFields = getFieldDefinitions(forms, selectedFormTitle);

    useEffect(() => {
        
        // When referrer (Client) is selected, it will use the clientID to make an API request
        // Once response is received successfully, the getFieldDefinitions function will be used 
        // to distinguish whether client has more than one form in the received json object.
        if (clientID) {
            const params = {
                ClientID: clientID,
                ReturnMultiple: true
            }
            api.transact("GetReferralForm", params)
                .then(r => r.apiResult)
                .then(r => Array.isArray(r) ? r : [r])
                .then(r => setForms(r))
                .catch(err => setErr(err.message));
        }
    }, [api, clientID]);

    // When a form is selected from the list of forms, this function will run 
    // and sets the SelectedFormTitle causing selection of field definitions.
    const formSelect = newSelectedForm => {
        setSelectedFormTitle(newSelectedForm);
        setProgressState(PROG_STATE_SHOW_FORM);
    }
    
    return <>
        <TwoSwitch test={null === formFields} >
            <FormList forms={forms} clientTitle={clientTitle} 
                onCancel={onCancel} 
                formSelect={formSelect} />
            <ReferralFormOrThanks 
                fieldDefinitions={formFields}
                api={api}
                clientId={clientID}
                onCancel={onCancel}
                setProgressState={setProgressState}
            />  
        </TwoSwitch>
        <LoadingIndicator loading={null === forms} />
        <TwoSwitch test={err} >
		    {err}
		</TwoSwitch>
    </>
}

// This handler function will take array of forms and will check whether length 
// of array is 1 or not. If the length is more than one, and the user hasn't 
// already selected something a list of forms that will be generated
// This way, the user clicks on the client name and will be able
// to see the list of forms associated with the clientID and be able to select the form they need.
const getFieldDefinitions = (forms, selectedFormTitle) => {

    const getFormSpec = () => {
        const formsArr = forms ?? [];
        const numForms = formsArr.length;
        if (0 === numForms) {
            return null;
        } else if (1 === numForms) {
            return formsArr[0];
        } else if (null === selectedFormTitle){
            return null;
        }else {
            return forms.find(f => f.ConfigTitle === selectedFormTitle);
        }
    }

    // Function for the fileUpload
    // To use the file upload, this function detects whether controlType is fileUpload or not,
    // and adds name and text for the field if it is fileUpload. This is because the API 
    // recognises 'Files' name for files upload functions.
    const fixFileUpload = (Field) => {
        const newResult = Field?.map(obj=>{
            if(obj.controlType === 'FileUpload'){
                obj.name = 'Files'
                obj.text = 'Upload a File'
            }
            return obj
        })
        return newResult?? null;
    }

    const fieldArray = getFormSpec()
    ?.ReferralPage.Field;

    const result = fixFileUpload(fieldArray);

    return result;
}



export default FormOrFormSelect;