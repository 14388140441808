import React        from "react";
import { useState } from "react";
import toBoolean from "../../util/toBoolean";

const Checkbox = props => {

    const {name, text, autocomplete, validating,
        required, handleChange, validationWarning} = props;

    const value = toBoolean( props.getOwnFieldValue(), false);

    let className = 'form__checkbox';
    if(validating){
        if( required && !value ){
            className += ' input-validate-highlight';
        }
    }

    const handleChangeInternal = e => 
    handleChange({target: {name, value: e.target.checked}});

    return <>
        <div className="form__field form__field--full-width" >
            <label htmlFor={name} className="form__label">
                {text}
                {required && <span style={{color: 'red'}}> *</span>}
            </label>

            <input 
                type='checkbox' 
                name={name} 
                id={name} 
                data-testid={name} 
                onChange={handleChangeInternal}
                checked={value} 
                autoComplete={autocomplete}
                className={className} />

        </div>
        {value === false && validationWarning !== undefined  ? <div className="alert required">{validationWarning}</div> : <></>}
    </>
};

export default Checkbox;