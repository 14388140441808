import React                            from 'react';
import makeFieldDefinitionsCanonical    from './FormFunctions/makeFieldDefinitionsCanonical';

import { fieldToComponent }             from './FormFunctions/fieldComponentMapping';
import testFieldOrTabIfEvaluatingFalse  from './FormFunctions/testFieldOrTabIfEvaluatingFalse';
import { getFieldData } from './FormFunctions/filterDataForFieldDefinitions';

/*
 * REACT component to display a list of form fields, to make a form
 */
const UnValidatedForm = props => {

    const { fieldDefinitions, formFieldData,
        validating, onChange, api, createOptionalOverrideFieldComponent, objectIndex } = props;

    const components =
        filterDuplicates(makeFieldDefinitionsCanonical(fieldDefinitions))
        .filter(f => testFieldOrTabIfEvaluatingFalse(f, formFieldData))
            .map(f => {
                const fieldComponent = getFieldComponentForJSONDefinition({...f}, createOptionalOverrideFieldComponent);
                const props = getPropsForComponent({...f}, api, formFieldData, validating, onChange, objectIndex);
                return React.createElement(fieldComponent, props);
            });


    return <>
        {components}
    </>
}

const getPropsForComponent = (f, api, formFieldData, validating, onChange, objectIndex) => {

    const getOwnFieldValue = () => getFieldData(formFieldData, f, objectIndex);
    const getAllFieldData  = () => formFieldData;
    const props = { 
        ...f, 
        Options: f.options,
        handleChange: handleChange(onChange, f),
        api, 
        values: formFieldData, 
        validating,
        getOwnFieldValue, 
        getAllFieldData
    };

    return props;
}

// gets a change handler for a specific fieldDef and returns an event handler wrapping onChange
const handleChange = (onChange, fieldDefinition) => event => onChange( {...event, fieldDefinition});

/**
 * If there's a duplicate, set the second on to have a special control type
 * @param {*} fieldDefinitions
 * @returns
 */
const filterDuplicates = fieldDefinitions => {

    const fieldNames = {};
    const newDefs = [];
    fieldDefinitions.forEach( fd => {
        const fieldName = fd.name;
        if(!fieldNames.hasOwnProperty(fieldName)){
            newDefs.push({...fd});
        }
        fieldNames[fieldName] = null; // just a marker
    });

    return newDefs;
}

const getFieldComponentForJSONDefinition = (fieldDefinition, createOptionalOverrideFieldComponent) => {

    const overrideComponent = typeof createOptionalOverrideFieldComponent === 'function' ?
        createOptionalOverrideFieldComponent(fieldDefinition) : null;
    const overrideProvided = typeof overrideComponent === 'function';

    const result = overrideProvided ? overrideComponent : fieldToComponent(fieldDefinition);
    return result;
}

export default UnValidatedForm;
