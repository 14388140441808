import React, { useState, useEffect } from 'react';
import UnValidatedForm from "../UnvalidatedForm";
import filterDataForFieldDefinitions from "../FormFunctions/filterDataForFieldDefinitions";

/* 
 * React component to display a Questionnaire form.
 */

const answers = {
    "Question": {},
    "Total": 0
}

const Questionnaire = props => {

    const { name, text, handleChange, enabled, width, Question = [],
        otherOption, validating, required, autoComplete, controltype, 
        TableHeadingText, TableHeadingNumber, getAllFieldData, object } = props;

    const values = getAllFieldData();

    const [total, setTotal] = useState(0);

    // Uses filterDataForFieldDefinitions component to filter out the FieldDefinitions and
    // uses the field data accumulated to calculate the total score.
    const calculateTotalScore = () => {
        let valuesForObject;
        if(object){
            if(typeof values[object] === 'undefined'){
                valuesForObject = {};
            }else{
                valuesForObject = values[object];
            }
        }else{
            valuesForObject = values;
        }
        const thisComponentValues = filterDataForFieldDefinitions(valuesForObject, Question);
        let totalScore = 0;
        for (const score in thisComponentValues){
            totalScore += parseInt(thisComponentValues[score]);
        }

        return totalScore;
    }

    useEffect(()=>{
        setTotal(calculateTotalScore());
    },[values]);

    // 
    useEffect(()=>{
        setTotalScoreInFormData();
    },[total])

    const setTotalScoreInFormData = () => {

        let totalScoreObjectData = {};

        const totalScoreName = name + 'Score';

        totalScoreObjectData[totalScoreName] = total;

        myHandleChange({target: {name: totalScoreName, value: total }} );
    }

    const myHandleChange = e => {

        handleChange(e);  
    }

    // conditional statement to distinguish whether the 
    // form's controltype is questionnaire or tabledquestionnaire.
    const isTableLayout = controltype === 'tabledquestionnaire';

    const questionnaireForm =
        isTableLayout ?
            <TableQuestionnaireForm {...{...props, handleChange: myHandleChange}} />:
            <UnValidatedForm
                fieldDefinitions={Question}
                formFieldData={values}
                onChange={myHandleChange}
            />
    return <div className={isTableLayout ? "tabledQuestionnaireForm" : "questionnaireForm"} >
        {questionnaireForm}
        <p>Score: {total}</p> 
    </div> 
}

const TableQuestionnaireForm = props => {

    const { handleChange, Question = [],
        TableHeadingText, TableHeadingNumber, object, getAllFieldData } = props;

    const childQuestions = Question.map(q => ({ ...q, object }))

    const values = getAllFieldData();

    const overrideComponent = Question => {
        if ('radiobutton' === Question.controltype) {
            return tableDataComponent;
        } else {
            return; 
        } 
    }

    return <table>
        {/* Table Heading from TableHeading property which is an array.*/}
        <thead>
            {console.log(TableHeadingText, TableHeadingNumber)}
            <tr className="questionRow">{TableHeadingText?.map(td => <th>{td}</th>)}</tr>
            <tr className="questionRow">{TableHeadingNumber?.map(td => <th>{td}</th>)}</tr>
        </thead>

        {/* Table Body */}
        <tbody>
            <UnValidatedForm
                fieldDefinitions={childQuestions}
                formFieldData={values}
                onChange={handleChange}
                createOptionalOverrideFieldComponent={overrideComponent}
            />
        </tbody>
    </table>
}

const tableDataComponent = props => {

    const { getOwnFieldValue, value: defaultValue = '',
    name, handleChange: parentHandleChange } = props;

    const value = getOwnFieldValue() ?? defaultValue;

    return (
        <>
            <tr> 
                {/* Table Question Text */}
                <td className="tableQuestion"><p>{props.text}</p></td>
 
                {/* td for radio buttons */}
                {props.Item.map((data)=>{
                    return (
                        <>
                            <td  
                                className="tableRadioButton"
                                key={name+data.Value}
                            >
                                <input 
                                    type="radio" 
                                    name={name} 
                                    value={data.Value} 
                                    checked={data.Value?.toString() === value?.toString() ? true : false}
                                    onChange={parentHandleChange}
                                />
                            </td>
                        </>
                    )
                })}
            </tr>
        </>
    )
}

export default Questionnaire;
