import React, { useCallback }       from 'react';
import toBoolean                    from '../../util/toBoolean';

// sends the files wrapped in an event object as thou
const sendOnChangeEventToParent = (onChange, files, name) => {

    const newEvent = {
        preventDefault: () => true,
        target: {
            name,
            value: files
        }
    };

    onChange(newEvent);
}

const SimpleFileUpload = ({ handleChange, api, name, text, noFileUpload = false, multiple, required = false }) => {

    if (!noFileUpload && (typeof api !== 'object' || typeof api.transact !== 'function')) {
        throw new Error('When noFileUpload is not set, an instance of API must be provided with which to upload files');
    }

    const multipleBool = toBoolean(multiple);

    const handleChangeInternal = useCallback(e => {

        e.preventDefault();
        
        const files = e.target.files;

        if (noFileUpload) {
            // send just the files from 
            sendOnChangeEventToParent(handleChange, files, name);
        } else {
            api.uploadFiles(files)
                .then(r => r.apiResult)
                .then(uploadedFiles => sendOnChangeEventToParent(handleChange, uploadedFiles, name))
                .catch(e => console.log("FAIL", e));
        }
            
    },
    [api, handleChange, name, noFileUpload]);

    return <fieldset className="form__field form__field--full-width" >
            <label htmlFor={name}  className="form__label__fileUpload">
                <legend>
                    {text ?? 'File Upload'}
                    {required && <span style={{color: 'red'}}> *</span>}
                </legend>
                <input id={name} type="file" onChange={handleChangeInternal} name={name} multiple={multipleBool} />
            </label>

        </fieldset>
}

export default SimpleFileUpload;