import React, { useCallback, useState } from "react";
import { ValidatedForm, OKCancel, TwoSwitch, LoadingIndicator } from "../lib/iprs-react-library/src/index.js";
import { useEffect } from "react";
import Documents from "./FormFileOverrides/Documents";
import TimeTextBox from "./TMPFieldOverrides/TimeTextBox";
import { AEROMED_MITIE_HOLDING_ROOMS_CLIENT_ID } from "../App";

const ReferrerForm = ({
    api,
    fieldDefinitions,
    clientId,
    onFormSubmitted,
    onCancel
}) => {
    const [formFieldData, setFormFieldData] = useState({});

    const [message, setMessage] = useState("Submit");
    const [loading, setLoading] = useState(false);

    const [formIsValid, setFormIsValid] = useState(false);
    const [, setFormTouched] = useState(false);
    const [validating, setValidating] = useState(false);

    const [error, setError] = useState(null);

    const [formFieldsInternal, setFormFieldsInternal] =
        useState(fieldDefinitions);

    // Had to do this in a hury -- see WR-472
    const isAeromed = clientId === AEROMED_MITIE_HOLDING_ROOMS_CLIENT_ID;
    const aPIMessageID = isAeromed
        ? "SaveAeromedReferralForm"
        : "SaveReferralForm";

    // Client ID is added to the fieldFormData which is needed property for API request.
    useEffect(() => {
        setFormFieldData({ ClientId: clientId });
    }, [clientId]);

    const submit = useCallback(
        e => {
            const { datesValid, datesInOrder } = isAeromed
                ? datesAndTimesValid(formFieldData)
                : { datesValid: true, datesInOrder: true };
            if (formIsValid && datesValid && datesInOrder) {
                setLoading(true);
                api.transact(aPIMessageID, { Data: formFieldData })
                    .then(r => {
                        setLoading(false);
                        onFormSubmitted(r.apiResult);
                        setError(null);
                    })
                    .catch(err => {
                        setLoading(false);
                        setError(err.message);
                        setMessage("Submit");
                    });
            } else {
                setValidating(true);
                setMessage("Submit");

                let newErrorMessage = "";
                if (!datesValid) {
                    newErrorMessage +=
                        "The dates and times you have entered are not all valid.";
                } else if (!datesInOrder) {
                    newErrorMessage += ` Dates and times are not in order. 
                        Please check that Mobile Time is after Call Time, On Scene Time is after 
                        Mobile Time and Clear Time is after On Scene Time.`;
                }
                setError(newErrorMessage);
            }
        },
        [api, formFieldData, formIsValid, onFormSubmitted, aPIMessageID, isAeromed]
    );

    // If the user selects a site we load a division list
    const siteFieldValue = Object.hasOwn(formFieldData, "Site")
        ? Number.parseInt(formFieldData.Site)
        : null;
    // Checking changes made to the 'fieldFormData' state.
    //
    // - When the fieldFormData has a property with name 'site',
    // API request needs to be made and update the property with name 'facility'
    // which will be used to populate the drop down select list.
    // api.transact("DivisionList", params) retrieves list of facilities and
    // creates newFacilitiesArray which contains the list and it is used to create newFormFields
    // setFormFields(newFormFields) updates the array which is used to generate the form.
    useEffect(() => {
        if (siteFieldValue) {
            setError('');
            api.transact("DivisionList", { FacilityID: siteFieldValue })
                .then(r => {
                    // call with a change function to avoid recursive dependency WR-406
                    setFormFieldsInternal(oldFormFields => {
                        const index = oldFormFields.findIndex(field => {
                            return field.name === "Division";
                        });

                        const extractedArray = r.apiResult;

                        let newFacilitiesArray = [];

                        extractedArray.forEach(obj => {
                            const newFacilityArray = {
                                Text: obj.FacilityName,
                                Value: obj.FacilityID
                            };
                            newFacilitiesArray.push(newFacilityArray);
                        });

                        const newFormFields = [...oldFormFields];

                        newFormFields[index] = {
                            ...oldFormFields[index],
                            Item: newFacilitiesArray
                        };

                        return newFormFields;
                    });
                })
                .catch(err => {
                    setError(err.message);
                });
        }
    }, [api, siteFieldValue, setFormFieldsInternal, setError]);

    // Cancels the form and resets the state of 'FormFields' and 'Form'
    // to its default states and it will be used by useEffect() in 'ProgressBar' component
    // and use it for it's conditional statement to display correct position in the progress
    const cancelForm = () => {
        setMessage("Clear");

        setFormFieldsInternal([]);
        // setForm(false);
        onCancel();
        // generateReferrersList()
    };

    const handleFormFieldDataChange = useCallback(
        newData => {
            setFormTouched(true);
            setFormFieldData(newData);
        },
        [setFormTouched, setFormFieldData]
    );

    return (
        <>
            <form>
                <h2>Referrer Form</h2>
                <ValidatedForm
                    validating={validating}
                    fieldDefinitions={formFieldsInternal}
                    formFieldData={formFieldData}
                    setFormFieldData={handleFormFieldDataChange}
                    setDataIsValid={setFormIsValid}
                    api={api}
                    createOptionalOverrideFieldComponent={createOptionalOverrideFieldComponent(
                        isAeromed
                    )}
                />
                <hr />
                <div class="button-holder">
                    <OKCancel
                        onOK={submit}
                        onCancel={cancelForm}
                        okText={message}
                        enabled={!validating || formIsValid}
                    />
                </div>
            </form>

            <LoadingIndicator loading={loading} />

            {/* inline-block just clears another following spane that splits the lines of text */}
            <TwoSwitch test={error}><span style={{display: 'inline-block', clear: 'right' }}>{error}</span></TwoSwitch>
        </>
    );
};

/**
 * Override the Documents control type.
 * Also specific Aeromed date input fields which should be fixed in
 * the library for all clients.
 * @param {object} fieldDefinition
 * @returns react component
 */
const createOptionalOverrideFieldComponent = isAeromed => fieldDefinition => {
    if ("documents" === fieldDefinition.controltype) {
        return props => <div>{React.createElement(Documents, props)}</div>;
    } else if (
        isAeromed &&
        ("timetextbox" === fieldDefinition.controltype ||
            "datetimetextbox" === fieldDefinition.controltype)
    ) {
        return props => <div>{React.createElement(TimeTextBox, props)}</div>;
    } else {
        return null;
    }
};

/* do some custom checks because the library doen't handle dates properly.
 Warning this is the same as in the createCasePage.jsx file in the aeromed 
 project.
*/
const datesAndTimesValid = formFieldData => {
    const isISODateTime = dateTimeString => {
        // parse with regex
        const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
        return regex.test(dateTimeString);
    };

    const parseISODateTime = dateString => {
        return Date.parse(dateString);
    };

    const callTimeIsValid = isISODateTime(formFieldData.CallTime);
    const mobileTimeIsValid = isISODateTime(formFieldData.MobileTime);
    const onSceneTimeIsValid = isISODateTime(formFieldData.OnSceneTime);

    const finalTimeIsValid = isISODateTime(formFieldData.ClearTime);

    const datesValid =
        callTimeIsValid &&
        mobileTimeIsValid &&
        onSceneTimeIsValid &&
        finalTimeIsValid;

    const callTime = parseISODateTime(formFieldData.CallTime);
    const mobileTime = parseISODateTime(formFieldData.MobileTime);
    const onSceneTime = parseISODateTime(formFieldData.OnSceneTime);

    const finalTime = parseISODateTime(formFieldData.ClearTime);

    const datesInOrder =
        callTime < mobileTime &&
        mobileTime < onSceneTime &&
        onSceneTime < finalTime;

    return { datesValid, datesInOrder };
};

export default ReferrerForm;
