import React, {useState, useEffect} from 'react';

/* 
 * React component to display a drop down box. Uses an HTML select.
 */
const RadioButton = props => {
    const {name, text, values, handleChange, enabled, width, Options: items = [],
        otherOption, validating, required, autoComplete, validationWarning } = props;
    
    const value = props.getOwnFieldValue();
    
    const [otherInputValue, setOtherInputValue] = useState('');

    // State to store which radio button has been checked.
    const [radioButtonStatus, setRadioButtonStatus] = useState([name]);

    // Actual JSX that will be rendered in the form.
    //const [radioButtons, setRadioButtons] = useState([]);
     
    const otherInputValueSet = otherInputValue? true: false;
    const otherOptionOn      = otherOption?     true: false;
    
    const showOtherInList    = otherOptionOn && otherInputValueSet && !(items.map( i => i.Value ).includes( value ));
    const showOtherInput     = value === '__other_special_value';
    
    // Codes to determine which class the container for Radiobutton to be defined with.
    const widthInt = Number.parseInt( width );
    let wrapperClass;

    if(100 <= widthInt && 200 > widthInt){
        wrapperClass = 'form__radio-wrapper form__radio-wrapper--width-100';
    }else{
        wrapperClass = 'form__radio-wrapper ';
    }
    
    let className = 'form__dropdown ';
    if(validating){
        if( required && (!value || value === '0') ){
            className += ' input-validate-highlight';
        }
    }

    // The handler that executes when radiobutton is clicked
    const checkBoxClickHandler = (e) => {
        handleChange(e);
    }

    // function to determine whether the list of RadioButton will be aligned horizontally or vertically.
    const listAlignDirection = () =>{
        const numberOfWordsCounter = (words) => {
            const wordsArray = words.split(" ");
            return wordsArray.length;
        }

        const arrayOfStringLengths = items.map(i => [numberOfWordsCounter(i.Text), i.Text.length])
        
        let wordsTotal = 0;
        let lengthsTotal = 0;

        const arrayLength = arrayOfStringLengths.length

        for (let i = 0 ; i < arrayLength; i++){
            wordsTotal += arrayOfStringLengths[i][0];
            lengthsTotal += arrayOfStringLengths[i][1];
        }

        const avgWordsAndLength = [wordsTotal/arrayLength, lengthsTotal/arrayLength];

        if(avgWordsAndLength[0] > 1 || avgWordsAndLength[1] > 10){
            return 'vertical'
        } else {
            return 'horizontal'
        }
    }

    // function to convert boolean to string and make them all lowercased.
    const comparer = (dataValue, jsonValue) => {
        return dataValue?.toString() === jsonValue?.toString() ? true : false
    }

    const radioButtons = items.map( i => 
        {
            const id = name + '_' + i.Value;
            return <div className="form__radioInput" key={id}>
                <input 
                    type="radio" 
                    key={i.Text} 
                    name={name} 
                    id={id}
                    value={i.Value}
                    checked={comparer(value, i.Value)} 
                    // checked={i.Value === value ? true : false}
                    onChange={checkBoxClickHandler}
                />
                <label htmlFor={id}>{i.Text}</label>
            </div>
        }
    );

    const [test, setTest] = useState([]);

    return  <div> 
            <fieldset className='form__field form__field--full-width' >
                <label className="form__label__radioButton">
                    <legend>{text}{required && <span style={{color: 'red'}}> *</span>}</legend> 
                    <div className={wrapperClass + listAlignDirection()}>
                        {radioButtons}
                    </div>
                </label>
            </fieldset>

            
            {showOtherInput && <div className='form__field form__field--full-width' ><div className='form__field form__field--full-width' >
            <label className="form__label" htmlFor={name} for={ name } >
                    {otherOption}
                    {required && <span style={{color: 'red'}}> *</span>}
                </label>
                <input  name={name} 
                        value={otherInputValue} 
                        placeholder={'Enter ' + otherOption}
                        onChange={e=>setOtherInputValue(e.target.value)} 
                        onBlur={handleChange}
                        autoComplete={ autoComplete} />
                        
                        <p>{JSON.stringify(autoComplete)}</p>
            </div></div> }
            
            {value === undefined && validationWarning !== undefined ?<div className="alert required">{validationWarning}</div> : <></>}
        </div>
}
 

export default RadioButton;
