import React from 'react'
import check from '../images/check.svg';

const PasswordResetSuccess = ({setSuccessStatus, setIsPwReset}) => {

    const backToLogin = (e) => {
        e.preventDefault();
        setSuccessStatus(false);
        setIsPwReset(false);
        // Changing the url to the root without redirecting the browser.
        const newData = null;
        const newUrl = '/';
        const newTitle = 'Welcome to IPRS Health - Login'
        window.history.pushState(newData, newTitle, newUrl)
    }

    return (
        <div id="passwordChangeSuccessMessage">
            <img 
                src={check}
                className='CheckImageForPasswordChange' 
                alt='check icon'
            />
            <p
                className='passwordChangeSuccessMessageText'
            >
                Your password has been changed successfully.
            </p>
            <button
                className='passwordChangeSuccessMessageButton'
                onClick={(e)=>(backToLogin(e))}
            >
                Back to Login
            </button>
        </div>
    )
    }

export default PasswordResetSuccess