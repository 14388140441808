
import Footer from "./components/Footer";

import "./css/styles.css";

import AppMainPart from "./components/AppMainPart";
import LoginAndPWReset from "./components/LoginAndPWReset/LoginAndPWReset";
import { TwoSwitch } from "./lib/iprs-react-library/src/index.js";
import AddReferrer from "./components/AddReferrer/AddReferrer";

const App = ({ api, urlPath, urlToken }) => {
    
    const doAddReferrer = (/AddReferrer.*/i).test(urlPath)
    return (
        <>
            <LoginAndPWReset api={api} urlPath={urlPath} urlToken={urlToken}>
                <TwoSwitch test={doAddReferrer} >
                    <AddReferrer api={api} urlToken={urlToken} urlPath={urlPath} />
                    <AppMainPart api={api} />
                </TwoSwitch>
            </LoginAndPWReset>
            <Footer />
            {/* <noscript>
		<p>Please enable JavaScript to view this page</p>
	</noscript> */}
        </>
    );
};

// Special treatment - See WR-472
const AEROMED_MITIE_HOLDING_ROOMS_CLIENT_ID = 212654;

export {AEROMED_MITIE_HOLDING_ROOMS_CLIENT_ID};
export default App;
