import { createElement, useCallback, useEffect, useState } from "react";
import Logo from "../images/logos/iprshealth.svg";

import {
    LoginOverlay,
    TwoSwitch
} from "../../lib/iprs-react-library/src/index.js";
import Header from "./Header";
import PasswordReset from "./PasswordReset";

const LoginAndPWReset = ({ api, urlPath, urlToken, children }) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [err, setErr] = useState(null);

    // this is messy - add referrer doesn't need login as it checks it's own token
    const unauthenticatedContentURL = /AddReferrer.*/i.test(urlPath);
    const isPwResetURL = /passwordReset.*/i.test(urlPath);

    // State to store boolean value to switch between PWResetLayer and LoginOverlay.
    // If it is set to true, PWResetLayer will be displayed, if false, it will display LoginOverlay
    const [isPwReset, setIsPwReset] = useState(isPwResetURL);

    // 1. When the app starts, it will check to validate the token if
    //    URL contains token using the 'getURLSearchParam' component from the 'iprs-react-library'.
    //    When it is authenticated, 'loggedIn' state will be set to true.

    useEffect(() => {
        if (urlToken && !unauthenticatedContentURL && !isPwResetURL) {
            api.validateLoggedIn(urlToken) // check existing stored
                .then(token => {
                    setLoggedIn(true);
                    api.setToken(''+token);
                })
                .catch(e => {
                                        setErr(e.message);
                    localStorage.setItem("Token", null);
                });
        }
    }, [api, urlToken, unauthenticatedContentURL, isPwResetURL]);

    // 2. If token didn't get validated, or URL token is non existent, 'LoginOverlay' component
    //    will be displayed and only when 'loggedIn' state is true, it will switch to 'ReferrerDetails'
    //    component.
    const handleLoginEvent = useCallback(
        action => {
            const isLoggedIn = "Logged in" === action;
            setLoggedIn(isLoggedIn);
        },
        [setLoggedIn]
    );

    useEffect(() => {
        document.body.classList.add("background__gradient");
    }, []);

    return (
        <>
            <Header handleLoginEvent={handleLoginEvent} api={api} />
            <div className="container">
                <main className="main">
                    <div className="main__top">
                        <div className="main__top-logo">
                            <img src={Logo} alt="IPRS Health Logo" />
                        </div>
                        <h1 className="main__top-title">
                            Welcome to IPRS Health's Services...
                        </h1>
                    </div>
                    <TwoSwitch test={!(loggedIn || unauthenticatedContentURL)}>
                        <div className="main__section">
                            {isPwReset ? (
                                <>
                                    <PasswordReset
                                        api={api}
                                        urlToken={urlToken}
                                        setIsPwReset={setIsPwReset}
                                    />
                                </>
                            ) : (
                                <LoginOverlay
                                    handleLoginEvent={handleLoginEvent}
                                    api={api}
                                >
                                    {{
                                        legend: "", // don't want anything for WR
                                        usernameLabel: "Email"
                                    }}
                                </LoginOverlay>
                            )}
                        </div>
                        <>{createElement("div", null, children)}</>

                        <TwoSwitch test={err}>{err}</TwoSwitch>
                    </TwoSwitch>
                </main>
            </div>
        </>
    );
};

export default LoginAndPWReset;
