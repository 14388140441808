
const PROG_STATE_LOGGED_IN          = 'loggedIn';
const PROG_STATE_SHOW_FORM          = 'ShowForm';
const PROG_STATE_SUBMITTED          = 'Thanks';

const ProgressBar = ({ progressState }) => {

    let progress;

    switch (progressState) {

        case PROG_STATE_LOGGED_IN:
            progress =
                [
                    [
                        ['progress__stage--active'],
                        [''],
                        ['']
                    ],
                    ['progress__bar--40'],
                    ['Step 1 of 3']
                ];
            break;
        case PROG_STATE_SHOW_FORM:
            progress =
                [
                    [
                        [''],
                        ['progress__stage--active'],
                        ['']
                    ],
                    ['progress__bar--60'],
                    ['Step 2 of 3']
                ];
            break;
        case PROG_STATE_SUBMITTED:
            progress =
                [
                    [
                        [''],
                        [''],
                        ['progress__stage--active']
                    ],
                    ['progress__bar--100'],
                    ['Step 3 of 3']
                ];
            break;
        default:
            progress = [
                [
                    [false],
                    [false],
                    [false]
                ],
                ['progress__bar--20'],
                ['Step 1 of 3']
            ];
    }

    return (
        <div>
            <div className="background__grey">
                <ol className="progress__stages">
                    <li className={"progress__stage " + progress[0][0]}>Select a service</li>
                    <li className={"progress__stage " + progress[0][1]}>Referrer form</li>
                    <li className={"progress__stage " + progress[0][2]}>Thank you</li>
                </ol>
                <div class={"progress__bar " + progress[1]}></div>
            </div>
            <p class="progress__bar-step">
                {progress[2]}
            </p>
        </div>
    )
}

export {PROG_STATE_SHOW_FORM, PROG_STATE_LOGGED_IN, PROG_STATE_SUBMITTED}
export default ProgressBar