import React                from 'react';

import DateTextBox          from '../Fields/Date/DateTextBox';
import DateTextBoxDesign    from '../Fields/Date/DateTextBoxDesign';
import TimeTextBox          from '../Fields/TimeTextBox';
import DropDownList         from '../Fields/DropDownList';
import DropDownListDesign   from '../Fields/DropDownListDesign';
import Address              from '../Fields/AddressCapture';
import AddressDesign        from '../Fields/AddressCaptureDesign';
import SectionBreak         from '../Fields/SectionBreak';
import TextBox              from '../Fields/TextBox';
import TextBoxDesign        from '../Fields/TextBoxDesign';
import Heading              from '../Fields/Heading';
import HeadingDesign        from '../Fields/HeadingDesign';
import TwoTextBox           from '../Fields/TwoTextBox';
import TwoTextBoxDesign     from '../Fields/TwoTextBoxDesign';
import InfoBox              from '../Fields/InfoBox';
import InfoBoxDesign        from '../Fields/InfoBoxDesign';
import MultiCheckbox        from '../Fields/MultiCheckbox.jsx';
import safeEval             from '../Eval/EvalFunction';
import toBoolean            from '../../util/toBoolean';
import SimpleFileUpload     from '../Fields/SimpleFileUpload';
import RadioButton          from '../Fields/RadioButton';
import Checkbox             from '../Fields/Checkbox';
import Questionnaire        from '../Fields/Questionnaire';
import Slider               from '../Fields/Slider';
import DateBox              from '../Fields/DateBox';

const filterOutFieldsWithIfEvaluatingFalse = (f, values) => {
  let result = true;
  if (f.hasOwnProperty('if')) {
    const fieldName = f.name;
    const thisValue = values[fieldName];
    result = safeEval(f.if, thisValue, values);
  }
  return result;
}



const fieldComponents = {
    
    address:             [Address,       AddressDesign],
    heading:             [Heading,       HeadingDesign],
    twotextbox:          [TwoTextBox,    TwoTextBoxDesign],
    dropdownlist:        [DropDownList,  DropDownListDesign],
    radiobutton:         [RadioButton,   DropDownListDesign],
    multiselect:         [MultiCheckbox, DropDownListDesign],
    datetextbox:         [DateTextBox,   DateTextBoxDesign],
    datebox:             [DateBox, null],
    timetextbox:         [TimeTextBox,   null],
    sectionbreak:        [SectionBreak,  'div'],
    'break':             [SectionBreak,  'div'],
    text:                [TextBox,       TextBoxDesign],
    checkbox:            [Checkbox,      TextBoxDesign],
    email:               [TextBox,       TextBoxDesign],
    tel:                 [TextBox,       TextBoxDesign],
    info:                [InfoBox,       InfoBoxDesign],
    label:               [TextBox,       TextBoxDesign],
    spacer:              [InfoBox,       InfoBoxDesign],
    fileupload:          [SimpleFileUpload,          null],
    questionnaire:       [Questionnaire, DropDownListDesign],
    tabledquestionnaire: [Questionnaire, DropDownListDesign],
    percentage:          [Slider, TextBoxDesign],
}

const controlTypeLCFromFieldDescriptor = (field) => {
    const { controltype = '' } = field;
    const controlTypeLC = (''+controltype).trim().toLowerCase();

    let result;
    if('label' === controlTypeLC){
        /* we don't want to support label control type. It's been used
        previously for HTML or a read only input/ */
        result = field.hasOwnProperty('value')? 'text': 'info';
    }else{
        result = controlTypeLC;
    }

    return result;
}

/**
* Gets a field object for the typename.
*/
const fieldToComponent = field => {
    const controlTypeLC = controlTypeLCFromFieldDescriptor(field);
    const component = fieldComponents[controlTypeLC]?.[0] ?? TextBox;

    return component;
}

const fieldToDesignComponent = field => {

    const controlTypeLC = controlTypeLCFromFieldDescriptor(field);
    const component = fieldComponents[ controlTypeLC ]?.[1] ?? TextBoxDesign;
    return component;
}

/* Validate a group field such as address or two text box */
const validateGroupField = ( field, values ) => {

    const requiredNames = 
            Object.entries( field )
            .filter( ([k, f]) => f?.required )
            .map( ([k, f]) => f?.name );
    
    const valid = requiredNames.reduce( (accumulator, currentValue) => accumulator && values[ currentValue ], true );

    const result = valid? true: false;

    return result;
}

/* validate a value against a regex which may be null. Null regex always passes */
const validateRegex = ( regex, value ) => {
    
    let result = false;
    if ( regex ){
        try{
            const regExp = new RegExp( regex );
            if( value && regExp.test( value ) ){
                result = true;
            }
        }catch(e){
            console.log( 'Bad regular expression \'' + regex + '\'' );
        }
    }else { 
        result = true;
    }

    return result;
}

/* validate a single field such as */
const validateSingleField = ( field, values ) => {

    const thisValue = values[ field.name ];
    let valid;
    if (toBoolean(field.required)) {
        if( validateRegex( field.regex, thisValue ) ){
            valid = thisValue;
        }
    } else {
        valid = true;
    }
    
    const result = valid? true: false;

    return result;
}

/* validates whether a field is is currently valid (i.e the user has filled it 
in */
const validate = ( field, values ) => {

    let validator = ( field, values ) => true;
    
    if( 'address' === field.controltype || 'twotextbox' === field.controltype ){
        validator = validateGroupField;
    }else if( ['text', 'textbox', 'dropdownlist',
            'checkbox', 'email', 'tel', 'datetextbox','timetextbox'].includes( field.controltype?.toLowerCase() ) ){
        validator = validateSingleField;
    }
    
    const valid = validator( field, values );

    return valid;
}

export {fieldToComponent, fieldToDesignComponent};