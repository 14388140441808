import React                                    from    'react';
import TextBoxDesign                            from './TextBoxDesign'; 

/** REACT component for designing / configuring a double text field on a form. */
const TwoTextBoxDesign = ({field, fieldOptions, updateField}) => {
    
    const { field1 = {}, field2 = {} }                    = field;
    
    const handleChange = (updatedSubField, subFieldKey) => {
        
        const newField          = { ...field };
        delete newField.fields; // don't use any more
        newField[subFieldKey]   = updatedSubField;
        
        updateField( newField );
    }
    
    const updateField1 = e => handleChange(e, 'field1');
    const updateField2 = e => handleChange(e, 'field2');
    
    return  <div>
                <TextBoxDesign field={field1} fieldOptions={fieldOptions} updateField={updateField1} />

                <hr />

                <TextBoxDesign field={field2} fieldOptions={fieldOptions} updateField={updateField2} />
            </div>
};

export default TwoTextBoxDesign;